import { configuration } from "./../../helpers/config";

import axios from "axios";
export const dealerData = async (lob) => {
  try {
    let response = await axios.get(`${configuration.APP_URL_CONTENTS}/dealer?lob=${lob}`);
    
    return response;
  } catch (err) {
    throw err;
  }
};
