import React, { forwardRef } from "react";
import Style from "./Forms.module.css";
import styles from "../Tool-tip/tool-tip.module.css";
import Image from "next/image";
import { useRouter } from 'next/router';
import {configuration} from "../../../helpers/config";
import { Tooltip } from "react-tooltip";

// SelectInput component accepts various props for customization
// eslint-disable-next-line react/display-name
const SelectInput = forwardRef(
  (
    {
      error,
      label,
      required,
      name,
      mandatory,
      items,
      value,
      placeholder,
      handleChange,
      tooltipContent,
      showTooltip,
      descript,
      nameKey,
      iconSource,
      imageVar,
      isPlaceholderDisabled,
      disabled,
      selectedModelCode,
      modelCodePage,
      ...otherProps
    },
    ref,
  ) => {
    const router = useRouter();
    const { modelname, modelcode } = router.query;
    return (
      <>
        {/* Container for the select input and label */}
        <div className={Style.select__container}>
     {/* Label container with tooltip positioned at the end */}
      <div className="flex items-center justify-between w-full h-[28px]">
        {/* Label + Required Asterisk */}
        <div className="flex starstyle">
          {label && (
            <label
              className={Style.label}
              htmlFor={name}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
          {required && <span>*</span>}
        </div>

  {/* Tooltip icon positioned at the end */}
  {showTooltip && (
    <div className="flex items-center">
      <Image
        data-tooltip-id={name}
        src={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
        height="15"
        width="15"
      />
      <Tooltip
        id={name}
        src={imageVar}
        variant="light"
        className="shadow-lg z-10"
      >
        <div className="tool-tip-wrap flex items-center justify-between">
          <div className="w-[20px] flex items-center mr-1">
            <Image src={iconSource} width={15} height={15} />
          </div>
          <p className={styles.toolTip_desc}>{descript}</p>
        </div>
      </Tooltip>
    </div>
  )}
</div>
          {/* Select input */}
          <select
            className={error ? Style.inputError : Style.input}
            required={mandatory}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            {...otherProps}
            ref={ref}
          >
            {/* Placeholder option */}
            <option value="" disabled={isPlaceholderDisabled}>
              {placeholder}
            </option>

            {/* Map over items and create options */}
            
            {items?.length > 0 &&
      items.map((item, index) => (
        <option
          key={index}
          value={JSON.stringify(item)}
          disabled={item.disabled}
          selected={
            otherProps.page === "finance-page"
                      ? item?.model_name?.toLowerCase() ===
                        otherProps?.selectedValue?.toLowerCase()
                      : (item?.model_code !== undefined && item?.model_code==modelCodePage || item.selected ||  item?.model_code !== undefined && item?.model_code==selectedModelCode || item?.model_code !== undefined && item?.model_code==modelcode)
                  }
                >
                  {/* Display the item's name, model_name, or the specified nameKey */}
                  {item?.name?.toUpperCase() || item?.model_name?.toUpperCase() || item[nameKey]?.toUpperCase() || item?.variant_name?.toUpperCase() || item?.methodName?.toUpperCase()}
        </option>
      ))}
          </select>
          {/* Display error message if error exists */}
          <p className={Style.error}>{error}</p>
        </div>
      </>
    );
  },
);

export default SelectInput;
