import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import AppReducer from "./Reducer";

const reducer = combineReducers({
  app: AppReducer,
});

const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware(),
  // do not forget this
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
