import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BackToTop from "../back-to-top";
import LinkButton from "../button";
import BrandLogo from "./brand-logo";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import useSWR from "swr";
import { configuration } from "../../helpers/config";
import axios from "axios";
import { Constants } from "../../helpers/constants";

// A custom data fetching function using axios
// const fetcher = (url) =>
//   axios({
//     method: "get",
//     url: url,
//     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//     origin: "*",
//     withCredentials: false,
//   }).then((response) => response.data);

export default function Footer({ footer }) {
  // Fetch data using SWR (data fetching library)
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
   
  // const { data, error } = useSWR(
  //   `${configuration.APP_URL_CONTENTS}/menus?lob=${LOB_VALUE===null? 1:LOB_VALUE}`,
  //   fetcher
  // );
  // const [footerData, setFooterData] = useState([]);
  // const [footerWidget, setFooterWidget] = useState([]);
  const date = new Date();
  const year = date.getFullYear();
  // const LobValue = useSelector(({ app }) => app.LobValue);
  // const reduxData = useSelector(({ app }) => app);
  // Use useEffect to process fetched data and update state
  // useEffect(() => {
  //   // Filter the data to get the 'footer' menu items
  //   let footerArray = Array.isArray(data) && data?.filter((item) => item.key === "footer");
  //   if (LobValue !== null) {
  //     footerArray = Array.isArray(footerArray) && footerArray?.filter((item) => item.lob == LobValue);
  //   }
  //   let dealerFilteredFooter = Array.isArray(footerArray) && footerArray?.filter(
  //     (item) => item.dealer_code == reduxData.dealerCode,
  //   );
  //   data?.length > 0 && setFooterData(dealerFilteredFooter[0]?.nestableArray);
  // }, [data, reduxData.dealerCode, LobValue]);

  // useEffect(() => {
  //   // Fetch footer widgets data
  //   const fetch_widget = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${configuration.APP_URL_CONTENTS}/widget/footer?lob=${LOB_VALUE}&dealer_code=${reduxData.dealerCode}`,
  //       );
  //       setFooterWidget(response?.data[0].Widget);
  //     } catch (err) {
  //       // Handle error while getting widgets
  //     }
  //   };
  //   if (reduxData.dealerCode != "") {
  //     fetch_widget();
  //   }
  // }, [reduxData.dealerCode]);

  // Function to render footer widgets
  // const FooterWidget = () => {
  //   const footer_widget =
  //     footerWidget?.length > 0 &&
  //     footerWidget.map((widget, item) => {
  //       return (
  //         <>
  //           <div className="footer-contacts grid grid-cols-12 mt-[40px]">
  //             <div className="col-span-4 hidden md:block lg:block"></div>
  //             <div className="footer-flex">
  //               <div className="footer-contact-model col-span-12 md:col-span-8 lg:col-span-8 md:pl-4 lg:pl-4 pb-[60px]">
  //                 <h3 className="font-suzukiBold text-primary-blue text-default mb-[25px]">
  //                   {widget.title}
  //                 </h3>
  //                 <div
  //                   dangerouslySetInnerHTML={{ __html: widget.description }}
  //                 ></div>
  //               </div>
  //             </div>
  //           </div>
  //         </>
  //       );
  //     });
  //   return footer_widget;
  // };

  const FooterWidget = ({widgetmenu}) => {
    const footer_widget =
    widgetmenu?.length > 0 &&
    widgetmenu.map((widget, item) => {
        return (
          <>
            {/* <div className="footer-flex"> */}
            <div className="footer-contact-model mb-[20px] md:w-[21%] lg:w-[21%] w-[75%] md:mb-[30px] lg:mb-[30px]">
              <h3 className="font-suzukiBold text-primary-blue text-default mb-[25px]">
                {widget.title}
              </h3>
              <div
                className="unreset"
                height="43px"
                width="172px"
                dangerouslySetInnerHTML={{ __html: widget.description }}
              ></div>
            </div>
            {/* </div> */}
          </>
        );
      });
    return footer_widget;
  };
  return (
    <>
    {
     <div>
      <hr className="border-gray mb-[50px] mt-[70px] hidden md:block"></hr>
      <footer id="footerSection" className="container-fluid">
        {/* Render brand logo and mobile menu */}
        <div className="w-full mb-[50px] block md:hidden">
          <div className="brand-logo-container">
            <div className="container mx-auto">
              <Link href="/" className="flex items-center" passHref>
                <BrandLogo
                  Id="BrandLogo"
                  // logoSrc={`${configuration.IMG_STATIC_URL}assets/images/logo.svg`}
                  logoSrc={`${configuration.IMG_STATIC_URL}assets/images/${process.env.NEXT_PUBLIC_SHOW_ANNIVERSARY_LOGO=="true" &&  process.env.NEXT_PUBLIC_ANNIVERSARY_LOGO_FOOTER?process.env.NEXT_PUBLIC_ANNIVERSARY_LOGO_FOOTER :"logo.svg"}`}
                 

                  className={"block w-auto h-8 mr-3"}
                  height={29.7}
                  width={155}
                />
              </Link>
            </div>
          </div>

          {/* Render mobile footer menu */}
          {footer?.footer?.nestableArray &&  footer?.footer?.nestableArray.length == 0 ? (
            <div className="flex items-center justify-center">
              <h3>{Constants.generic.DATA_NOT_FOUND}</h3>
            </div>
          ) : (
            footer?.footer?.nestableArray?.map((fmenu, i) => (
              <React.Fragment key={i}>
                {fmenu.children.length > 0 ? (
                  <Disclosure key={fmenu._id}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="w-full border-b-[1px] border-light-gray ">
                          <div className="container flex flex-row justify-between h-[60px] items-center py-2 mx-auto">
                            <span className="font-suzukiBold text-default">
                              {fmenu.text}
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? "" : "rotate-180 transform"
                              } h-5 w-5 text-purple-500`}
                            />
                          </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className="py-2 bg-light-gray">
                          <div className="container mx-auto">
                            <ul className="footer__menu--submenu">
                              {fmenu?.children?.map((smenu, i) => (
                                <li
                                  key={i + 1}
                                  className="font-suzukiBold text-default"
                                >
                                  <a
                                    href={
                                      smenu.url.includes("/")
                                        ? smenu.url
                                        : `/${smenu.url}`
                                    }
                                    target={
                                      smenu.target.includes("_")
                                        ? smenu.target
                                        : `_${smenu.target}`
                                    }
                                  >
                                    {smenu.text}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ) : (
                  <Disclosure key={fmenu._id}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="w-full border-b-[1px] border-light-gray ">
                          <div className="container flex flex-row justify-between h-[60px] items-center py-2 mx-auto">
                            <span className="font-suzukiBold text-default">
                              {fmenu.text}
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? "" : "rotate-180 transform"
                              } h-5 w-5 text-purple-500`}
                            />
                          </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className="py-2 bg-light-gray">
                          <div className="container mx-auto">
                            <ul className="footer__menu--submenu ">
                              <li className="font-suzukiBold text-default h-[60px]">
                                <Link href={fmenu.url}>{fmenu.text}</Link>
                              </li>
                            </ul>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              </React.Fragment>
            ))
          )}
          <div className="ml-10" >
         {configuration.COUNTRY_CODE == 'PH' && <img src={`${configuration.IMG_STATIC_URL}assets/images/CORSeal.png`} style={{ height: '100px', width: '100px' }} />}
	         </div>
        </div>
        {/* Render desktop footer menu */}
        <div className="container mx-auto">
          <div className="hidden md:block">
            <div className="grid grid-cols-12 gap-10">
              <div className="col-span-12" id="footerLinks">
                <ul className="flex grid justify-around grid-cols-5 gap-3 text-default font-suzukiBold ">
                  {/* {footerData?.map((item, index) => ( */}
                  { footer?.footer && footer?.footer?.nestableArray?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.children.length > 0 ? (
                        <div className="mb-[58px]">
                          <p className="desktopFooter-heading">{item.text}</p>
                          {item.children.map((link) => (
                            <li className="mb-[6px]" key={item._id}>
                              <a
                                key={link.text}
                                target={
                                  link.target.includes("_")
                                    ? link.target
                                    : `_${link.target}`
                                }
                                href={
                                  link.url.includes("/")
                                    ? link.url
                                    : `/${link.url}`
                                }
                                className="inline-block font-thin font-suzukiRegular hover:underline"
                              >
                                {link.text}
                              </a>
                            </li>
                          ))}
                        </div>
                      ) : (
                        <li className="mb-4" key={item._id}>
                          <div>
                            <p className="desktopFooter-heading">{item.text}</p>
                            <a
                              key={item.text}
                              target={
                                item.target.includes("_")
                                  ? item.target
                                  : `_${item.target}`
                              }
                              href={
                                item.url.includes("/")
                                  ? item.url
                                  : `/${item.url}`
                              }
                              className="inline-block font-suzukiRegular hover:underline" id="footerSubLinks"
                            >
                              {item.text}
                            </a>
                          </div>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-[41px] border-gray hidden md:block" />
          {/* Render social media links */}
          <div className="social_links" id="socialLinksSection">
            <div className="hidden col-span-4 lg:block md:block">
              {/* <div className="mb-6 md:mb-0 inline-block"> */}
              <div className="mb-6 md:mb-0 flex ">
                <Link href="/" className="flex items-center" passHref>
                  <BrandLogo
                    Id="BrandLogo"
                    // logoSrc={`${configuration.IMG_STATIC_URL}assets/images/logo.svg`}
                    logoSrc={`${configuration.IMG_STATIC_URL}assets/images/${process.env.NEXT_PUBLIC_SHOW_ANNIVERSARY_LOGO=="true" &&  process.env.NEXT_PUBLIC_ANNIVERSARY_LOGO_FOOTER?process.env.NEXT_PUBLIC_ANNIVERSARY_LOGO_FOOTER :"logo.svg"}`}
                  

                    width={176}
                    height={33.72}
                    className={"block w-auto h-8 mr-3"}
                  />
                </Link>
                <div>
                 {configuration.COUNTRY_CODE == 'PH' && <img src={`${configuration.IMG_STATIC_URL}assets/images/CORSeal.png`} />}
	               </div>
              </div>
            </div>
            {configuration.SOCIAL_ICON === "true" && (
              <>
                {/* Render social media buttons */}
                {/* {newData[0]?.social_media_visible?.linkedin && ( */}
                   { footer && footer?.datasetting[0]?.social_media_visible?.linkedin && (
                  <div className="col-span-6 md:col-span-2 lg:col-span-2">
                    <LinkButton
                      type={"social__icon--linkedin"}
                      Id="Linkedin"
                      buttontext={Constants?.footer?.LINKEDIN}
                      href={
                        footer?.datasetting.length ? footer?.datasetting[0]?.social_media?.linkedin : ""
                      }
                      classname="button_dark_arrow"
                    />
                  </div>
                )}
                {footer && footer?.datasetting[0]?.social_media_visible?.twitter && (
                  <div className="col-span-6 md:col-span-2 lg:col-span-2">
                    <LinkButton
                      Id="Twitter"
                      type={"social__icon--twitter"}
                      buttontext={Constants?.footer?.TWITTER}
                      href={
                        footer?.datasetting.length ? footer?.datasetting[0]?.social_media?.twitter : ""
                      }
                      classname="button_dark_arrow"
                    />
                  </div>
                )}

              {footer && footer?.datasetting[0]?.social_media_visible?.facebook && (
                  <div className="col-span-6 md:col-span-2 lg:col-span-2">
                    <LinkButton
                      Id="Facebook"
                      type={"social__icon--facebook"}
                      buttontext={Constants?.footer?.FACEBOOK}
                      href={
                        footer?.datasetting.length ? footer?.datasetting[0]?.social_media?.facebook : ""
                      }
                      classname="button_dark_arrow"
                    />
                  </div>
                )}
                  {footer && footer?.datasetting[0]?.social_media_visible?.instagram && (
                  <div className="col-span-6 md:col-span-2 lg:col-span-2">
                    <LinkButton
                      Id="Instagram"
                      type={"social__icon--instagram"}
                      buttontext={Constants?.footer?.INSTAGRAM}
                      href={
                        footer?.datasetting.length ? footer?.datasetting[0]?.social_media?.instagram: ""
                      }
                      classname="button_dark_arrow"
                    />
                  </div>
                )}
                 {footer && footer?.datasetting[0]?.social_media_visible?.youtube && (
                  <div className="col-span-6 md:col-span-2 lg:col-span-2">
                    <LinkButton
                      Id="Youtube"
                      type={"social__icon--youtube"}
                      buttontext={Constants?.footer?.YOUTUBE}
                      href={
                        footer?.datasetting.length ? footer?.datasetting[0]?.social_media?.youtube : ""
                      }
                      classname="button_dark_arrow"
                    />
                  </div>
                )}
                 {footer && footer?.datasetting[0]?.social_media_visible?.tiktok && (
                  <div className="col-span-6 md:col-span-2 lg:col-span-2">
                    <LinkButton
                      Id="Tiktok"
                      type={"social__icon--tiktok"}
                      buttontext={Constants?.footer?.TIKTOK}
                      href={
                        footer?.datasetting.length ? footer?.datasetting[0]?.social_media?.tiktok : ""
                      }
                      classname="button_dark_arrow"
                    />
                  </div>
                )}
              </>
            )}
          </div>

          {/* Render footer widgets */}
          <div className="footer-contacts grid grid-cols-new-14 mt-[40px] md:gap-[40px] lg:gap-[40px]" id="footerContacts">
            <div className="col-span-4 hidden md:block lg:block"></div>
            <div className="footer-flex col-span-8 flex md:flex-wrap flex-col md:flex-row lg:flex-row md:gap-[40px] lg:gap-[40px]" id="footerWidget">
              {/* {LobValue !== null && <FooterWidget />} */}
              {footer &&  footer?.widgetmenu.length >0  && <FooterWidget widgetmenu={footer?.widgetmenu} />}
            </div>
          </div>
        </div>
        {/* Render copyright section */}
        <div className="copyright_details h-[80px] bg-[#F4F4F4] flex items-end">
          <div className="container mx-auto">
            <span className="text-sm pb-[20px] inline-block text-[#001151] font-suzukiRegular text-small">
              © {year} {Constants.footer.COPYRIGHT}
            </span>
          </div>
        </div>
      </footer>
      {/* Render BackToTop button */}
      <BackToTop />
      </div>
    }
    </>
  );
}
