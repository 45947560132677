import React, { forwardRef } from "react";
import Image from "next/future/image";

//component to display brand logo in website.
// eslint-disable-next-line react/display-name
const BrandLogo = forwardRef(
  ({ className, logoSrc, height, width, href ,Id}, ref) => {
    return (
      <a ref={ref} href={href} id={Id}>
        <div className={process.env.NEXT_PUBLIC_SHOW_ANNIVERSARY_LOGO=="true" ? "brand-logo_anniversary brand-logo_container" : "brand-logo_container"}>
          <Image
          id="brandLogo"
            className={`${className} brand-logo`}
            src={logoSrc}
            alt="Suzuki"
            fill
            layout="fill"
          />
        </div>
      </a>
    );
  },
);
export default BrandLogo;
