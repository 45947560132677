import React from "react";
import CookieConsent from "react-cookie-consent";
import { Constants } from "./../helpers/constants";
import { configuration } from "../helpers/config";
import { useSelector } from "react-redux";
const ACCEPT_COOKIES = Constants.accept_cookies;
export default function AcceptCookies() {
  const isLobPage = useSelector(({ app }) => app.isLobPage);
  return (
    <>
      {!isLobPage && (
        <CookieConsent
          location="bottom"
          buttonText={ACCEPT_COOKIES.ACCEPT_BUTTON}
          cookieName="suzuki-accept-cookie"
          style={{ background: "#110051" }}
          expires={150}
          overlay={true}
          buttonWrapperClasses="cookie-button-wrapper"
          contentClasses="content-wrapper"
          disableButtonStyles={true}
        >
                     <div className="flex lg:flex-row flex-col items-center">
                     {configuration.COUNTRY_CODE == 'PH' && <img src={`${configuration.IMG_STATIC_URL}assets/images/CORSeal.png`}  width={100}/>}
          <p>
            {ACCEPT_COOKIES.CONTENT}{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="/pages/Data-Privacy-Notice"
              rel="noopener noreferrer"
              target="_blank"
            >
              {ACCEPT_COOKIES.DATA_PRIVACY_NOTICE}
            </a>
            {ACCEPT_COOKIES.ACCEPT}
          </p>
          </div>
        </CookieConsent>
      )}
    </>
  );
}
