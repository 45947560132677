import React from "react";
import Style from "./Forms.module.css";

export default function TitleText({ children }) {
  return (
    <>
      <div className={Style.title}>{children}</div>
    </>
  );
}
