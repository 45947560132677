export const TOGGLE_TEST_DRIVE_FORM = "TOGGLE_TEST_DRIVE_FORM";
export const TOGGLE_TEST_DRIVE_FORM_2W = "TOGGLE_TEST_DRIVE_FORM_2W";
export const TOGGLE_BROCHURE_FORM = "TOGGLE_BROCHURE_FORM";
export const TOGGLE_BROCHURE_FORM_2W = "TOGGLE_BROCHURE_FORM_2W";
export const TOGGLE_ENQUIRY_FORM = "TOGGLE_ENQUIRY_FORM";
export const TOGGLE_FINANCE_FORM = "TOGGLE_FINANCE_FORM";
export const TOGGLE_SERVICE_FORM = "TOGGLE_SERVICE_FORM";
export const TOGGLE_SERVICE_FORM_2W = "TOGGLE_SERVICE_FORM_2W";
export const TOGGLE_CONTACT_FORM = "TOGGLE_CONTACT_FORM";
export const TOGGLE_GET_QUOTE_FORM = "TOGGLE_GET_QUOTE_FORM";
export const TOGGLE_GET_QUOTE_FORM_2W = "TOGGLE_GET_QUOTE_FORM_2W";
export const DEALER_DATA_FORM = "DEALER_DATA_FORM";
export const IS_DEALER_SELECTED = "IS_DEALER_SELECTED";
export const SELECTED_DEALER = "SELECTED_DEALER";
export const TOGGLE_ENQUIRY_FORM_2W = "TOGGLE_ENQUIRY_FORM_2W"; 


export const CAR_CONFIGURATOR_SUBMIT="CAR_CONFIGURATOR_SUBMIT";

export const PERSONAL_IDENTIFIABLE_INFORMATION_FORM="pii";
export const CONFIGURATOR_DATA="configurator_data";
export const SEND_MAIL_DEALER_CONFIGURATOR_DATA="send_to_dealer_configurator";
export const PERSONAL_IDENTIFIALBLE_FORM_RES="personal_identifier form response";



export const TOGGLE_IS_LOB_PAGE = "TOGGLE_IS_LOB_PAGE";

export const UPDATE_LOB_VALUE = "UPDATE_LOB_VALUE"

export const IS_DEALER_WEBSITE = "IS_DEALER_WEBSITE"

export const UPDATE_DEALER_CODE = 'UPDATE_DEALER_CODE'