import React, { forwardRef, useEffect, useState } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

// eslint-disable-next-line react/display-name
const PhoneOrInputConsent = forwardRef(
  ({ error, label, required, changeHandler, checkAll, ...props }, ref) => {
    // State to manage the checkbox values for phone and email consent
    const [inputValues, setInputValues] = useState({
      phone: false,
      email: false,
    });

    // Custom hook to run an effect after the component mounts
    useDidMountEffect(() => {
      // Set the initial checkbox values based on the checkAll prop
      setInputValues({
        phone: checkAll,
        email: checkAll,
      });
    }, [checkAll]);

    // Handle changes when a checkbox is clicked
    const handleChange = (e) => {
      // Update the inputValues state based on the clicked checkbox
      setInputValues({ ...inputValues, [e.target.name]: e.target.checked });
      // Invoke the changeHandler prop with the updated values
      changeHandler({ ...inputValues, [e.target.name]: e.target.checked });
    };

    return (
      <div ref={ref} >
        <div className=" flex starstyle">
       <p
  dangerouslySetInnerHTML={{ __html: label }}
/>
{/* Display an asterisk if required */}
{required && <span>*</span>}
</div>
        {/* Container for phone and email checkboxes */}
        <div className="flex gap-10">
          {/* Phone checkbox */}
          <div className="flex">
            <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
              <input
              id="phoneCheckbox"
                className="w-[18px] h-[21px]"
                name="phone"
                checked={inputValues.phone}
                type="checkbox"
                onChange={handleChange}
              />
            </span>
            <div className="relative w-full">
              <label className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default ">
                Phone
              </label>
              {/* Display an error message if there's an error */}
              {error && (
                <p className="text-[#FF0000] text-[12px] mb-0">
                  Please click to proceed!
                </p>
              )}
            </div>
          </div>
          {/* Email checkbox */}
          <div className="flex">
            <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
              <input
                className="w-[18px] h-[21px]"
                onChange={handleChange}
                checked={inputValues.email}
                name="email"
                type="checkbox"
              />
            </span>
            <div className="relative w-full">
              <label className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default ">
                Email
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default PhoneOrInputConsent;
