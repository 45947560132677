import Image from "next/image";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState, useRef } from "react";
import { configuration } from "../../helpers/config";
import { useRouter } from "next/router";
import { Constants } from "../../helpers/constants";

const MegaMenu = ({ data, models, index, setSelectedIndexProp }) => {
  const [modelsData, setModelsData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([0, 0]);
  const [url, setUrl] = useState("");

  // setting modal data to local state
  useEffect(() => {
    setModelsData(data[selectedIndex[0]].children);
  }, [data, selectedIndex]);

  function getImageKeys(models, modelName, variantName, colorName) {
    const imageKeys = [];
    models?.forEach((model) => {
      // Check if the model_name matches

      if (model?.model_name === modelName) {
        // Iterate through the variants to find the matching variant_name

        model?.variants?.forEach((variant) => {
          if (variant?.variant_name === variantName) {
            // Iterate through the colors to find the matching color_name

            variant?.colors?.forEach((color) => {
              if (color?.color_name === colorName) {
                // If all three values match, push the image key to the result
                imageKeys.push(color?.image);
              }
            });
          }
        });
      }
    });
    return imageKeys;
  }

  const router = useRouter();

  // redirect on press car model
  const handleRedirect = (url) => {
    router.push(url);
    setSelectedIndexProp(null);
  };

  const listRefs = [useRef([]), useRef([])];
  const [focusedList, setFocusedList] = useState(0);
  const itemRef = useRef(null);

  useEffect(() => {
    // Focus the currently selected item in the focused list
    if (listRefs[focusedList].current[selectedIndex[focusedList]]) {
      listRefs[focusedList].current[selectedIndex[focusedList]].focus();
    }
  }, [focusedList, selectedIndex]);

  const handleKeyDown = (e) => {
    if (e.key !== "Tab") {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "ArrowDown") {
      setSelectedIndex((prev) => {
        const newIndexes = [...prev];
        newIndexes[focusedList] = Math.min(
          newIndexes[focusedList] + 1,
          listRefs[focusedList].current.length - 1
        );
        return newIndexes;
      });
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prev) => {
        const newIndexes = [...prev];
        newIndexes[focusedList] = Math.max(newIndexes[focusedList] - 1, 0);
        return newIndexes;
      });
    } else if (e.key === "ArrowRight") {
      // Toggle between the lists
      setFocusedList((prev) => 1);
    } else if (e.key === "ArrowLeft") {
      // Toggle between the lists
      setFocusedList((prev) => 0);
    }
  };

  return (
    <div
      tabIndex="0"
      onKeyDown={handleKeyDown}
      className={` bg-[#ffff] absolute top-[55px]
        drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] cursor-default flex h-fit max-h-[88vh] w-[60vw]`}
    >
      {/*left menu list */}
      <div className="w-1/4 border-r border-[#757575] mt-2">
        {data?.map((el, index) => {
          return (
            <div
              ref={(el) => (listRefs[0].current[index] = el)}
              tabIndex={-1}
              key={index}
              style={{ outline: "none" }}
              className={`flex justify-between mx-4 my-3 text-base
                ${
                  selectedIndex[0] === index
                    ? "text-[#003690]"
                    : "text-[#757575]"
                } 
                ${selectedIndex[0] === index ? "border-b-2" : "border-0"} 
                ${
                  selectedIndex[0] === index
                    ? "font-suzukiBold"
                    : "font-suzukiRegular"
                }  uppercase cursor-pointer`}
              onClick={() => {
                setModelsData(el?.children);
                const arr = [index, 0];
                setSelectedIndex(arr);
              }}
            >
              {el?.text}

              <ArrowRightIcon height={20} width={20} />
            </div>
          );
        })}
      </div>
      {/* right models list view */}
      <div className="w-3/4 overflow-y-auto mt-8 grid">
        <h1 className="text-center font-suzukiHeadline text-xl px-5">
          {Constants?.mega_menu?.HEADING}
        </h1>

        <p className="text-center font-suzukiRegular text-[#757575] text-base px-40">
          {Constants?.mega_menu?.SUB_HEADING}
        </p>

        <div className="grid grid-cols-3 self-center">
          {modelsData?.map((el, index) => {
            return (
              <div
                className="grid justify-items-center hover:bg-[#E5E5E5] bg-[white] p-3 cursor-pointer"
                onClick={() => handleRedirect(el?.url)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleRedirect(el?.url);
                  }
                }}
                ref={(el) => (listRefs[1].current[index] = el)}
                tabIndex="-1"
              >
                <Image
                  src={`${configuration.IMG_URL}${getImageKeys(
                    models,
                    el.model,
                    el.variant,
                    el.color
                  )}`}
                  width={200}
                  height={150}
                  alt=""
                />
                <p className="text-center font-suzukiHeadline text-base uppercase">
                  {el?.text}
                </p>
                <div className=" bg-primary-blue rounded-lg text-[white] py-2 px-3 w-fit font-suzukiRegular text-sm">
                  Explore Model
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
