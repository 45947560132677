import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { toggleForm } from "../../redux/ActionCreator";
import { useDispatch } from "react-redux";
import { configuration } from "../../helpers/config";

// component used in float-bar to show individual icon buttons for forms
export default function FloatItem({
  src,
  itemName,
  onClick,
  Id,
  themeData,
  selectedSrc,
  show2w,
  form,
  form2w,
}) {
  const windoWidth = useWindowSize();
  const dispatch = useDispatch();

  return (
    <>
      <a
        className="group hover:cursor-pointer"
        id={Id}

        style={{ background: themeData?.floating_menu_bg_color }}
      >
        <div
        onClick={onClick}
          className="floater-icons"
          style={{ height: "60px", width: "50px", display:"flex", justifyContent:"center", alignItems:"center" }}
        >
          <img
            className="w-[20px] h-[20px]"
            src={
              themeData?.floating_menu_color==="#FFFFFF"
                ? selectedSrc
                : src
            }
            alt=""
          />
        </div>
        <p className="text-[8px] uppercase leading-[11px] md:mt-[5px] mt-[5px] mb-0 block md:block lg:hidden text-defaut-color font-suzukiBold">
          {itemName}
        </p>
        {show2w ? (
         
          <span
          className={`floating-sides after:absolute after:content-[''] lg:after:right-[-15px] after:left-[50%] md:after:left-[49%] lg:after:left-auto after:translate-x-[-50%] md:after:translate-x-[-50%] lg:after:translate-x-[0]  lg:after:top-[8px] after:top-auto md:after:top-auto after:bottom-[-17px] md:after:bottom-[-16px] lg:after:bottom-auto after:border-l-transparent md:after:border-l-transparent lg:after:border-t-transparent  after:border-t-inverted md:after:border-t-inverted after:border-[8px] after:border-transparent md:after:border-solid lg:after:border-l- md:shadow-lg shadow-lg p-0 md:py-[7px] md:px-0 absolute lg:left-[-130px] left-auto md:left-auto top-[-78px] md:top-[-62px] lg:top-auto md:rounded-[3px] rounded-[5px] md:w-[120px] w-[150px] lg:w-[120px] py-[10px] px-[5px] lg:pb-[7px] lg:px-0 lg:pt-[5px] group-hover:visible group-hover:ml-[6px] md:group-hover:scale-100 bg-inverted`}  
          id={Id}
            // style={{color: themeData?.floating_menu_color, background: themeData?.floating_menu_bg_color, textShadow: "0px 5px 13px rgb(0 0 0 / 45%)", fontSize: windoWidth.width > 1024 ? themeData?.floating_menu_font_size : "8px"}}
            style={{
              color: themeData?.floating_menu_color,
              background: themeData?.floating_menu_bg_color,
              fontSize:
                windoWidth.width > 1024
                  ? themeData?.floating_menu_font_size
                  : "8px",
            }}
          >
           
            {/* {itemName} */}

            {/* <div > */}
            <p className="text-[13px] leading-[14px] lg:mb-[3px] m-0 hidden md:hidden lg:block text-default text-[#909090] font-suzukiBold">
              {itemName}
            </p>
            <div className="flex justify-between md:pt-0 lg:pt-0  ">
              <div
                onClick={() => {

                  dispatch(toggleForm(form));
                }}
                className=" floater-icons p-[8px] lg:px-[2px] lg:py-[3px] w-[50%] text-center relative after:absolute after:right-0 after:top-0 after:bottom-0 after:bg-[#D9D8D9] after:w-[2px] after:content-['']"
                // style={{ height: "20px", width: "20px" }}
              >
                <img
                  className="w-[20px] h-[20px] mx-auto"
                  src={`${configuration.IMG_STATIC_URL}assets/images/floatbar/testCar.svg`}
                  alt=""
                />
              </div>

              <div
                className="floater-icons p-[8px] lg:px-[2px] lg:py-[3px] w-[50%] text-center "
                // style={{ height: "20px", width: "20px" }}
                onClick={() => {

                  dispatch(toggleForm(form2w));
                }}
              >
                <img
                  className="w-[20px] h-[20px] mx-auto   "
                  src={`${configuration.IMG_STATIC_URL}assets/images/floatbar/testBike2.svg`}
                  alt=""
                />
              </div>
            </div>
         
            {/* </div> */}
          </span>
        ) : (
          <span
            className={`floating-sides after:absolute after:content-[''] lg:after:right-[-15px] after:left-[50%] md:after:left-[49%] lg:after:left-auto after:translate-x-[-50%] md:after:translate-x-[-50%] lg:after:translate-x-[0]  lg:after:top-[8px] after:top-auto md:after:top-auto after:bottom-[-17px] md:after:bottom-[-16px] lg:after:bottom-auto after:border-l-transparent md:after:border-l-transparent lg:after:border-t-transparent  after:border-t-inverted md:after:border-t-inverted after:border-[8px] after:border-transparent md:after:border-solid lg:after:border-l-inverted md:shadow-lg shadow-lg p-0 md:py-[7px] md:px-0 absolute lg:left-[-130px] left-auto md:left-auto top-[-78px] md:top-[-62px] lg:top-auto md:rounded-[3px] rounded-[5px] md:w-[120px] w-[150px] lg:w-[120px] py-[10px] px-[5px] lg:pb-[7px] lg:px-0 lg:pt-[5px] group-hover:visible md:group-hover:scale-100`}
            // style={{color: themeData?.floating_menu_color, background: themeData?.floating_menu_bg_color, textShadow: "0px 5px 13px rgb(0 0 0 / 45%)", fontSize: windoWidth.width > 1024 ? themeData?.floating_menu_font_size : "8px"}}
            style={{
              color: themeData?.floating_menu_color,
              background: themeData?.floating_menu_bg_color || "white",
              fontSize:
                windoWidth.width > 1024
                  ? themeData?.floating_menu_font_size
                  : "8px",
            }}
          >
            {itemName}

            {/* <div > */}

            {/* </div> */}
          </span>
        )}
      </a>
    </>
  );
}
