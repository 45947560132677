import { Constants } from "../../helpers/constants";
import { configuration } from "../../helpers/config"

export const PhoneValidation = {
  required: Constants.forms.REQUIRED_FIELD,
  minLength: {
    value: process.env.NEXT_PUBLIC_PHONE_NUMBER_MIN_LENGTH,
    message: Constants.forms.PHONE_ERROR,
  },
  maxLength: {
    value: process.env.NEXT_PUBLIC_PHONE_NUMBER_MAX_LENGTH,
    message: Constants.forms.PHONE_ERROR,
  },
  pattern: {
    value: /^[0-9]+$/, // This regular expression allows only numeric characters
    message: Constants.forms.PHONE_SPECIAL_CHAR_ERROR,
  },
};

export const NameValidation = {
  // pattern: {
  //   value: /^[A-Za-z ]+$/,
  //   message: Constants.forms.NAME_PATTERN,
  // },
  maxLength: {
    value: 30,
    message: Constants.forms.NAME_ERROR,
  },
  required: Constants.forms.REQUIRED_FIELD,
};

export const TextValidation = {
  pattern: {
    value: /^[A-Za-z ]+$/,
    message: Constants.forms.NAME_PATTERN,
  },
};
export const EmailValidation = {
  required: Constants.forms.REQUIRED_FIELD,
  pattern: {
    value:
      /^[^<>()[\]\\,;:\%#^\s@\"$&!-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/gm,
    //value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,7}$/g,
    message: Constants.forms.EMAIL_ERROR,
  },
};
export const planningToPurchaseOptionsName =
  configuration.PLANNING_TO_PURCHASE_IN_NAME?.split("/");
export const planningToPurchaseOptionsCode =
  configuration.PLANNING_TO_PURCHASE_IN_CODE?.split("/");
export const planningToPurchaseOptions = [];
planningToPurchaseOptionsName?.map((planning, key) => {
  let planningToPurchase = {};
  planningToPurchase["name"] = planning;
  planningToPurchase["code"] = planningToPurchaseOptionsCode[key];
  planningToPurchaseOptions.push(planningToPurchase);
});

export const timeSlots = [
  { name: "8 AM - 9 AM", value: "08" },
  { name: "9 AM - 10 AM", value: "09" },
  { name: "10 AM - 11 AM", value: "10" },
  { name: "11 AM - 12 PM", value: "11" },
  { name: "12 PM - 1 PM", value: "12" },
  { name: "1 PM - 2 PM", value: "13" },
  { name: "2 PM - 3 PM", value: "14" },
  { name: "3 PM - 4 PM", value: "15" },
];

const currentHour = new Date().getHours();

// Add the 'disabled' key based on the current system time
export const currentTimeSlots = timeSlots?.map((slot) => {
  const slotHour = parseInt(slot.value);
  const slotTime = slotHour < 8 ? slotHour + 12 : slotHour;
  return { ...slot, disabled: slotTime <= currentHour };
});

export const communicationOptName =
  configuration.communicationOptionsName?.split("_");
export const communicationOptValue =
  configuration.communicationOptionsValue?.split("_");
export const communicationOptFlag =
  configuration.communicationOptionsFlag?.split("_");

export const communicationOptions = [];
communicationOptName?.map((communication, key) => {
  let communications = {};
  if (communicationOptFlag[key] == "true") {
    communications["name"] = communication;
    communications["value"] = communicationOptValue[key];
    communicationOptions.push(communications);
  }
});
communicationOptions.push({
  name: Constants.communicationOptions.BOTH,
  value: "1663701889014",
});

export const purchaseOptions = [
  { name: Constants.purchaseOptions.CREDIT, value: "credit" },
  { name: Constants.purchaseOptions.COUNTED, value: "counted" },
  { name: Constants.purchaseOptions.LEASE, value: "lease" },
];

export const selectFinanceOptions = [
  { name: "I already have a car loan", value: "existing_car_loan" },
  { name: "I need a car loan", value: "need_car_loan" },
  { name: "I will pay in cash", value: "pay_in_cash" },
];

export const downPaymentOptions = [
  { name: "20%", value: 20 },
  { name: "30%", value: 30 },
  { name: "40%", value: 40 },
  { name: "50%", value: 50 },
];

export const tenureOptions = [
  {
    name: "24 Months",
    value: 24,
    roi: 1.2375,
  },
  {
    name: "36 Months",
    value: 36,
    roi: 1.3,
  },
  {
    name: "48 Months",
    value: 48,
    roi: 1.4,
  },
  {
    name: "60 Months",
    value: 60,
    roi: 1.5,
  },
];

export const financeOptions = [
  {
    name: "I already have a car loan",
    value: "I already have a car loan",
  },
  {
    name: "I need  a car loan",
    value: "I need  a car loan",
  },
  {
    name: "I will pay in cash",
    value: "I will pay in cash",
  },
];

export const formNames = {
  ENQUIRY: "enquiry_form",
  CALC_PAYMENT: "finance_form",
  TEST_DRIVE: "test_drive_form",
  BROCHURE: "brochure_form",
  SERVICE: "service_form",
  CONTACT_US: "contact_form",
  GET_QUOTE: "get_quote_form",
  PERSONAL_IDENTIFICATION_INFORMATION: "pii",
  SEND_TO_DEALER: "send_to_dealer",

  ENQUIRY_2W: "enquiry_form_2w",
  CALC_PAYMENT_2W: "finance_form_2w",
  TEST_DRIVE_2W: "test_drive_form_2w",
  BROCHURE_2W: "brochure_form_2w",
  SERVICE_2W: "service_form_2w",
  CONTACT_US_2W: "contact_form_2w",
  GET_QUOTE_2W: "get_quote_form_2w",
  
  BROCHURE_MARINE: "brochure_form_marine",
  TEST_DRIVE_MARINE: "test_drive_form_marine"
};

export  const inquiryTypes = [
  { value: "1738218827041", name: Constants.forms.inquiryTypes?.brochureRequest ?Constants.forms?.INQUIRY_TYPES?.brochureRequest:"Brosúra igénylés" },
  { value: "1738219034420", name:Constants?.forms?.INQUIRY_TYPES?.inquiry?Constants?.forms?.INQUIRY_TYPES?.inquiry: "Érdeklődés" },
  { value: "1738219046712", name:Constants?.forms?.INQUIRY_TYPES?.suzukiPlusOffer?Constants?.forms?.INQUIRY_TYPES?.suzukiPlusOffer: "Suzuki+ ajánlat" },
  { value: "1738219076564", name: Constants?.forms?.INQUIRY_TYPES?.complaint?Constants?.forms?.INQUIRY_TYPES?.complaint:"Reklamáció" },
  { value: "1738219106695", name:Constants?.forms?.INQUIRY_TYPES?.recalls?Constants?.forms?.INQUIRY_TYPES?.recalls: "Visszahívások" },
  { value: "1738219135491", name:Constants?.forms?.INQUIRY_TYPES?.feedbackSuggestion?Constants?.forms?.INQUIRY_TYPES?.feedbackSuggestion: "Vélemény, javaslat" },
  { value: "1738219159710", name:Constants?.forms?.INQUIRY_TYPES?.other?Constants?.forms?.INQUIRY_TYPES?.other: "Egyéb" }
];

export const ServicesTypes=JSON.parse(JSON.stringify(process.env.NEXT_PUBLIC_SERVICES_TYPES))
