import translations from '../translation.json';
export const Constants = {...translations}



// export const Constants = {
//   currency: "MXN",
//   locale: "es-MX",
//   generic: {
//     DATA_NOT_FOUND: "Data not found",
//     PAGE_NOT_FOUND: "Page not found",
//     Four0Four: "404 - Page Not Found",
//   },
//   testimonial: {
//     HEADING: "TESTIMONIALS",
//     ERROR_FETCHING: "Error fetching Testimonials",
//   },
//   brandPage: {
//     FINANCE_CALCULATOR: "Finance Calculator",
//     BOOK_A_TEST_DRIVE: "Test Drive",
//     BRANDPAGE_MODEL1: "SEE WHICH ",
//     BRANDPAGE_MODEL2: "IS PERFECTLY RIGHT FOR YOU",
//     KEY_FEATURES: "KEY FEATURES",
//     PERFORMANCE: "PERFORMANCE",
//     BRANDPAGE_COMPARE: "Compare all models",
//     BRAND_BANNER_PRICE: "FROM",
//   },
//   cars: {
//     EXPERIENCE: "EXPERIENCE",
//     TODAY: "TODAY",
//     WE_THINK_YOU: "WE THINK YOU'LL LOVE",
//     TOPICS: "TOPICS",
//     RECENTLY_VIEWED: "RECENTLY VIEWED",
//   },
//   pages: {
//     CARD_BUTTON_TEXT: "SEE DETAILS",
//   },
//   homePage: {
//     HOMEPAGE_SUZUKI: "FIND YOUR PERFECT SUZUKI",
//     SUZUKI_ALL: "All",
//     SUZUKI_BODYTYPE: "Body Type",
//     SUZUKI_VEHICLETYPE: "Vehicle Type",
//     SUZUKI_RANKING: "Ranking",
//     SUZUKI_SITUATION: "Situation",
//     SUZUKI_FUNCTION: "Function",
//     SEE_DETAILS: "See Details",
//   },
//   findDealerPage: {
//     FIND_A_DEALER: "Find A Dealer",
//     NEW_SALE: "New Car Sales",
//     MOTABILITY: "Motability",
//     ALL: "All",
//     OUR_NETWORK: "OUR NETWORK",
//   },
//   expericencIcon: {
//     BOOK_A_TEST_DRIVE: "BOOK A TEST DRIVE",
//     DOWNLOAD_BROCHURE: "DOWNLOAD A BROCHURE",
//     FINANCE_CALCULATOR: "FINANCE CALCULATOR",
//   },
//   getQuote: {
//     CALCULATE: "Calculate",
//     COMPUTATION_WARNING:
//       "Computations are estimate only. Please contact any Suzuki Dealer for final quotation.",
//     APPLY_FINANCING: "APPLY FOR FINANCING",
//     GET_FINAL_QUOTE: "GET FINAL QUOTE",
//     SEARCH_AGAIN: "Search Again",
//     KEY_FEATURES: "KEY FEATURES",
//     PERFORMANCE: "PERFORMANCE",
//     QUOTE_REQUEST: "Quote Request Submitted Successfully",
//     QUOTE_REQUEST_ERROR: "Error while submitting Enquiry",
//     SELECT_A_CAR_MODEL: "Select a Car Model",
//     MODEL: "Model",
//     CHOOSE_A_VARIANT: "Choose Variant",
//     PREFFERED_VARIANT: "-Preferred Variant-",
//     CUSTOMER_INFORMATION: "Customer Information",
//     CONDITION: `By completing and submitting this form you consent to our policy
//     regarding information collection as outlined in our`,
//     TICK_THE_BOX: "Tick the box to let us know you’ve read and understood our",
//   },
//   footer: {
//     TWITTER: "Twitter",
//     FACEBOOK: "Facebook",
//     INSTAGRAM: "Instagram",
//     YOUTUBE: "Youtube",
//     LINKEDIN: "Linkedin",
//     COPYRIGHT: process.env.NEXT_PUBLIC_FOOTER_COPYRIGHT,
//     TIKTOK: "TikToK",
//   },
//   updateConsent: {
//     GET_OTP_BUTTON: "Get otp",
//     OTP_SENT_SUCCESSFULLY: "otp sent successfully",
//     OTP_VERIFIED_SUCCESSFULLY: "otp verfied successfully",
//     SUCCESSFULLY_VERIFIED_THE_OTP: "Successfully verified the otp",
//     INCORRECT_OTP: "Incorrect Otp",
//     SUCCESSFULLY_UPDATED_THE_CONSENT: "Successfully updated the consent",
//     VERIFY_OTP_BUTTON: "Verify otp",
//     VIEW_HISTORY: "View History",
//     UPDATE_WITHDRWAL_CONSENT: "Update/Withdraw Consent",
//     GDPR_RIGHTS: "GDPR Rights",
//     OUR_PRIVACY_POLICY: "Our Privacy Policy",
//     UPDATE_CONSENT_BUTTON: "Update Consent",
//     PLEASE_ENTER_THE_OTP: " PLEASE ENTER THE OTP",
//     NO_RECORD_FOUND: "No Record Found",
//     VERIFY_VIA_EMAIL: "Verify via Email",
//     VERIFY_VIA_MOBILE: "Verify via Mobile Number",
//     BACK_TO_HOME_PAGE: "Back To Homepage",
//     NO_CONSENT_FOUND_MOBILE:
//       "Sorry, we could not find any consent against this mobile number. If you have used email in previous interactions with us, please login using the same email for viewing your consents",
//     NO_CONSENT_FOUND_EMAIL:
//       "Sorry, we could not find any consent against this email ID. If you have used mobile number in previous interactions with us, please login using the same mobile number for viewing your consents",
//     NO_CONSENT_FOUND_EMAIL_AND_MOBILE:
//       "Sorry, we could not find any consent against this mobile number or email ID. Please make sure you're using the registered contact details or send your consent updating request to dpo@suzuki-digital.com",
//     INVALID_OTP:
//       "Invalid OTP. Please enter the correct OTP to verify your identity and proceed.",
//     VERIFY_OTP: " Please verify the otp received",
//     OTP_DO_NOT_MATCH: "otp received and otp entered do not match",
//     VALID_PHONE_ERROR: "Please enter a valid  number",
//     VALID_EMAIL_ERROR: "Please enter a valid email address",
//     ENTER_MOBILE_NUMBER: "Enter your mobile number:",
//     ENTER_EMAIL: "Enter your email:",
//     CHOOSE_DEVICE: " Choose where You want to get otp:",
//     SELECT_AN_OPTION: "Select an option",
//     CONSENT_UPDATE: "CONSENT UPDATE",
//     VERIFICATION: "VERIFICATION",
//     CHECK_YOUR: "Check your",
//     FOR_OTP: "For OTP",
//     MOBILE: "Mobile",
//     EMAIL: "Email",
//     SUBMIT: "Submit",
//     BACK: "BACK",
//     DID_NOT: "Didn",
//     T: "t",
//     RECEIVE_THE_OTP: "receive the OTP?",
//     RESEND_IN: "Resend in",
//     PLEASE_SELECT_A_DEVICE: "Please select a device",
//     MOBILE_NUMBER: "Mobile Number",
//     UPDATE_CONSENT: "UPDATE CONSENT & ACCESS GDPR RIGHTS",

//     ENTER_YOUR_DETAILS_TO_VERIFY: "Enter your details to verify",
//     EMAIL_SENT_SUCCESSFULLY: "Email sent successfully",
//     share_personal_details_with_dealer:
//       "Consent to share the personal information Suzuki Dealers",
//     call_centre_consent:
//       "Consent to validate the personal information through Call Centre",
//     phone_consent: "Consent to share phone",
//     email_consent: "Consent to share email",
//     privacy_policy_consent:
//       "Consent to agree with Personal Data Privacy policy",
//     tnc_consent: "Consent to agree with Terms and Conditions",
//     GDPR: {
//       RIGHT_OF_ACCESS_BY_DATA_SUBJECT: "Right of access by data subject",
//       RIGHT_TO_RECTIFICATION_BY_DATA_SUBJECT:
//         "Right to rectification by data subject",
//       RIGHT_TO_BE_FORGOTTEN_BY_DATA_SUBJECT:
//         "Right to be forgotten by data subject",
//       RIGHT_TO_RESTRICTION_OF_PROCESSING: "Right to restriction of processing",
//       RIGHT_TO_DATA_PORTABILITY: "Right to data portability",
//       RIGHT_TO_OBJECT: "Right to object",
//       AUTOMATED_INDIVIDUAL_DECISION_MAKING:
//         "Automated individual decision making including profiling",
//       TOOL_TIP: "Information about the access GDPR Rights",
//       PRIVACY_RELATED_RIGHTS: "YOUR PRIVACY RELATED RIGHTS",

//       PRIVACY_DECLARATION1:
//         "If you have declared your consent regarding certain collecting, processing, and use of your personal data (in particular regarding the receipt of direct marketing communication via email, SMS/MMS, fax, and telephone), ",
//       PRIVACY_DECLARATION2:
//         "you can withdraw this consent at any time with future effect. ",
//       PRIVACY_DECLARATION3:
//         "Such withdrawal will not affect the lawfulness of the processing prior to the consent withdrawal. ",
//       PRIVACY_DECLARATION4: "You can withdraw your consent below ",
//       OR_BY: "or by ",
//       PRIVACY_DECLARATION5: "contacting us via e-mail ",
//       AT: "at ",
//       PRIVACY_LINK: "privacy@suzuki.hu",
//       OR: " or ",
//       DATA_PROTECTION_LINK: "dataprotection@suzuki.ro",
//       PRIVACY_DECLARATION6: "by sending a mail to our seat",
//       PRIVACY_DECLARATION7:
//         " (Magyar Suzuki Co. Ltd., Schweidel. J. u. 52, Esztergom 2500, Hungary).",

//       PRIVACY_RIGHT_TITLE_1: "Right to request access to your personal data: ",
//       PRIVACY_RIGHT_1:
//         "You may have the right to obtain from us confirmation as to whether or not personal data concerning you is being processed, and, where that is the case, to request access to the personal data. However, this is not an absolute right, and the interests of other individuals may restrict your right of access. You may have the right to obtain a copy of the personal data undergoing processing free of charge. To the extent permitted by applicable data protection law, we may charge a reasonable fee based on administrative costs.",
//       PRIVACY_RIGHT_TITLE_2: "Right to request rectification: ",
//       PRIVACY_RIGHT_2:
//         "You may have the right to obtain from us the rectification of inaccurate personal data concerning you. Depending on the purposes of the processing, you may have the right to have incomplete personal data completed, including by means of providing a supplementary statement.",
//       PRIVACY_RIGHT_TITLE_3:
//         "Right to request erasure (right to be forgotten): ",
//       PRIVACY_RIGHT_3:
//         "You may have the right to obtain from us the erasure of personal data concerning you and we may be obliged to erase such personal data, if we do not have a legal obligation to store this type of data.",
//       PRIVACY_RIGHT_TITLE_4: "Right to request restriction of processing: ",
//       PRIVACY_RIGHT_4:
//         "You may have the right to obtain from us that we restrict the processing of your personal data. In such case, the respective data will be marked and may only be processed by us for certain purposes.",
//       PRIVACY_RIGHT_TITLE_5: "Right to request data portability: ",
//       PRIVACY_RIGHT_5:
//         "You may have the right to receive the personal data concerning you, which you have provided to us, in a structured, commonly used.",

//       SCROLL_FIELD_CONTENT1: "Under certain circumstances, ",
//       SCROLL_FIELD_CONTENT2: "you have the right to object, ",
//       SCROLL_FIELD_CONTENT3:
//         "on grounds relating to your particular situation, at any time to the processing of your personal data by us and we are required to no longer process your personal data. ",
//       SCROLL_FIELD_CONTENT4:
//         "Such right to object especially applies if we collect and process your personal data for profiling purposes in order to better understand your interests in our products and services or for certain types of direct marketing. ",
//       SCROLL_FIELD_CONTENT5:
//         "If you have a right to object and if you exercise this right, ",
//       SCROLL_FIELD_CONTENT6:
//         "your personal data will no longer be processed for such purposes by us, except we have legitimate compelling legitimate reasons for the processing ",
//       SCROLL_FIELD_CONTENT7:
//         "which override the interests, rights, and freedoms of the data subject, or for the establishment, exercise or defence of legal claims. Such a right to object may, in particular, not exist if the processing of your personal data is necessary to take steps prior to entering into a contract or to perform a contract already concluded. If you have given your consent to receive direct marketing via certain communication channels, you can easily withdraw your consent by clicking here.",

//       SUBMIT_GDPR_REQUEST_IN_DETAIL:
//         "Submit GDPR request for access rights related change",
//       DISCLAIMER: "Disclaimer",
//       PLEASE_WRITE_TO_US: "Please write to us on",
//       NO_RESPONSE_MESSAGE:
//         "in case you did not get any response within 21 days.",
//       REPLY_TO_EMAIL: "dpo@suzuki-digital.com",
//     },
//     HISTORY: {
//       SELECT_ALL: "SELECT ALL",
//       ALL_HISTORY: "ALL HISTORY",
//       CONSENT_HISTORY: "CONSENT HISTORY",
//       CONSENT_TO_AGREE_WITH: "Consent to agree with",
//       TERMS_AND_CONDTIONS: "Terms and Conditions",
//       LOADING_PLEASE_WAIT: "LOADING...PLEASE WAIT",
//     },
//     LAST_UPDATE: {
//       YOUR_LAST_UPDATE: "YOUR LAST UPDATE",
//       WITHDRAWAL_OF_CONSENT: "WITHDRAWAL OF CONSENT:",
//       MANDATORY_CONSENTS: "Mandatory Consents",
//       SELECT_ALL: "Select all of the below",
//       MARKETING_CHANNELS: "Marketing Channels",
//       MARKETING_BY_SUZUKI: "Marketing by Suzuki",
//       MARKETING_BY_DEALERS: "Marketing by Suzuki Dealers",
//       RIGHT_TO_WITHDRAW_CONSENT: "Right To Withdraw Consent",
//       WITHDRAW_REQUEST:
//         "Submit Consent Update/Withdraw Request Reason In Detail*",
//       RIGHT_TO_WITHDRAW_CONSENT_CONTENT:
//         "I can withdraw this consent at any time and I understand that such a withdrawal would not affect the lawfulness of such direct marketing activities before the withdrawal. I was informed that I can easily withdraw my consent by sending an email to ",
//       RIGHT_TO_WITHDRAW_CONSENT_CONTENT2:
//         " or unsubscribing on the unsubscribe link displayed in such communication.",
//       WITHDRAWAL_OF_CONSENT_DETAIL1:
//         "If you have declared your consent regarding certain collecting, processing, and use of your personal data (in particular regarding the receipt of direct marketing communication via email, SMS/MMS, fax, and telephone), ",
//       WITHDRAWAL_OF_CONSENT_DETAIL_BOLD1:
//         "you can withdraw this consent at any time with future effect. ",
//       WITHDRAWAL_OF_CONSENT_DETAIL2:
//         "Such withdrawal will not affect the lawfulness of the processing prior to the consent withdrawal. ",
//       WITHDRAWAL_OF_CONSENT_DETAIL_BOLD2:
//         "You can withdraw your consent below ",
//       WITHDRAWAL_OF_CONSENT_DETAIL3: "or by contacting us via e-mail at ",
//       PRIVACY_LINK: "privacy@suzuki.hu",
//       OR: " or ",
//       DATA_PROTECTION_LINK: "dataprotection@suzuki.ro",
//       YOUR_CONSENT_HAVE_BEEN_UPDATED: "Your consent has been updated",
//     },
//     POPUP:{
//       THANKUOU_MSG:"Thank you for submitting your request to our support team.",
//       WE_WILL_CONTACT : "We will contact you soon with the resolution of your query.",
//       OKAY:"Okay"
//     }
//   },
//   homeSuzukiCard: {
//     FROM: "From",
//     OFFER_PRICE: "Offer Price",
//     RABLA_PRICE: "Rabla Price",
//   },

//   terms_and_conditions: {
//     LINE1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//     Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
//     Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
//     Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit abdyh alfalfl.`,
//     LINE2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//     Duis aute irure dolor in reprehenderit in voluptate velit ess`,
//     LINE3: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//     Duis aute irure dolor in reprehenderit in voluptate velit ess`,
//   },
//   accept_cookies: {
//     CONTENT: ` This website uses cookies to enable it to perform properly, remember
//     your browsing history and to arrange contents that will match your
//     preferred interests more efficiently. The details of the cookies that
//     Suzuki Philippines Incorporated (SPH) use can be found on our`,
//     DATA_PRIVACY_NOTICE: `Data Privacy Notice`,
//     ACCEPT: `. By clicking the Accept button, closing this banner, scrolling this
//     page or clicking any part of it, you agree to our use of cookies.`,
//     ACCEPT_BUTTON: "Accept",
//   },
//   forms: {
//     OTHER_FIELD: "Other model name",
//     REQUIRED_FIELD: "This is a required field",
//     DATA_NOT_FOUND: "Data not found",
//     CLICK_TO_PROCEED: "Please click to proceed",
//     COMPLETE_CAPTCHA: "Please complete CAPTCHA",
//     CHOOSE_VARIANT: "Choose Variant",
//     PREFFERED_MODEL: "-Preferred Model-",
//     PREFFERED_CAR_MODEL: "Preferred Car Model",
//     DOWN_PAYMENT: "-Down Payment-",
//     DONW_PAYMENT_LABEL: "Down Payment",
//     PREFFERED_VARIANT: "-Preferred Variant-",
//     SELECT_DOWNPAYMENT: "Select Down Payment",
//     DURATION: "Duration",
//     SELECT_DURATION: "Select Duration",
//     PREFFERED_DEALERSHIP: "-Preferred Dealership-",
//     PREFFERED_DEALERSHIP_LABEL: "Preferred Dealership",
//     SELECT_DEALER_BRANCH: "-Select Dealer Branch-",
//     PREFFERED_TIME: "-Preferred Time-",
//     PREFFERED_TIME_LABEL: "Preferred Time",
//     PREFFERED_DATE: "Preferred Date",
//     VALID_CHOICE: "Select a valid choice",
//     PRIVACY_TEXT_PART1:
//       "By completing and submitting this form you consent to our policy regarding information collection as outlined in our",
//     PRIVACY_TEXT_PART2:
//       "Tick the box to let us know you’ve read and understood our ",
//     PRIVACY_TEXT_PART3: "before you continue.",
//     EMAIL_ERROR:
//       "Please enter a valid email address. For example johndoe@domain.com",
//     PHONE_ERROR:
//       "Please enter a valid contact number. For example (09051234567)",
//     PHONE_SPECIAL_CHAR_ERROR: "Please remove the spacial character",
//     NAME_PATTERN: "Please enter only alphabets",
//     NAME_ERROR: "Should not exceed 30 characters",
//     SUBMIT: "SUBMIT",
//     SUBMITTED: "Form Submitted",
//     SUBMIT_ERROR: "Error while submitting Form",
//     SELECT_A_VALID_CHOICE: "Please select a valid choice",
//     UNABLE_TO_COMPLETE_PAYMENT: "Unable to calculate payment",
//     SELECT_CAR_MODEL: "-Select Car Model-",
//     BOOK_A_SERVICE: "Book a Service",
//     FIRST_NAME: "First Name",
//     YOUR_FIRST_NAME: "Your First Name",
//     YOUR_LAST_NAME: "Your Last Name",
//     DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     LAST_NAME: "Last Name",
//     LAST_NAME_DESCRIPTON:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     MOBILE_NUMBER: "Mobile Number",
//     MOBILE_NUMBER_DESCRIPTOR:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     EMAIL: "Email",
//     EMAIL_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     YOUR_LOCATION: "Your Location in the Philippines",
//     LOCATION_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     PREFFERED_CAR_MODEL_LABEL: "Preferred Car Model",
//     YEAR_MODEL: "Year Model",
//     YEAR: "Year",
//     YEAR_MODEL_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     KM_READING: "KM Reading",
//     READING: "Reading",
//     READING_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     PLATE_NUMBER_CONDUCTION_STICKER: "Conduction Sticker / Plate Number",
//     PLATE_NUMBER_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     VIN_CHASIS_NUMBER: "VIN / Chassis Number",
//     VIN_CHASIS_NUMBER_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     PREFFERED_COMMUNICATION_MODE: "Preferred Communication Mode",
//     PURCHASE_TYPE: "PURCHASE TYPE",
//     SELECT_COMMUNICATION_MODE: "-Select Communication Mode-",
//     I_ACCEPT_PRIVACY_POLICY: "I ACCEPT PRIVACY POLICY",
//     ACCEPT_TEXT: ` By completing and submitting this form you consent to our policy
//     regarding information collection as outlined in our`,
//     PRIVACY_POLICY: " Privacy Policy",
//     TERMS_AND_CONDITIONS:"Term & Condition",
//     TICK_THE_TEXT: "Tick the box to let us know you’ve read and understood our",
//     BEFORE_YOU_CONTINUE: "before you continue.",
//     PLEASE_COMPLETE_FORM_TO_DOWNLOAD_BROCHURE:
//       "Please Complete Form To Download A Brochure",
//     NAME: "Name",
//     NAME_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     PHONE_MOBILE_NUMBER: "Phone / Mobile Number",
//     PHONE_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     SELECT_MODEL: "- Select Model -",
//     ENQUIRY_SUBMITTED: "Enquiry submitted, we will get back to you shortly!",
//     ENQUIRY_ERROR: "Error while submitting Enquiry",
//     CONTACT_US: "Contact Us",
//     ADDRESS: "Address",
//     ADDRESS_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     CONTACT_NUMBER: "Contact Number",
//     CONTACT_NUMBER_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     MESSAGE: "Message",
//     MESSAGE_PLACEHOLDER: "Enter Message Here",
//     T_AND_C_LINE1: "I have read and understood Suzuki’s",
//     PHONE_NUMBER_PLACEHOLDER: "Enter Phone Number",
//     EMAIL_PLACEHOLDER: "Enter Email",
//     ENQUIRE_NOW: "Inquire Now",
//     PREFFERED_MODEL_PLACEHOLDER: "-Preferred Model-",
//     QUOTATION_SUBMIT: "Quotation request submitted",
//     CALCULATE_PAYMENT: "Calculate Payment",
//     THIS_IS_A_REQUIRED_FIELD: "This is a required field.",
//     REQUEST_A_TEST_DRIVE: "Request A Test Drive",
//     MOBILE_NUMBER_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     YOUR_LOCATION_DESCRIPTION:
//       "This Information is necessary for identification, in order to manage and resolve your notification and/or requests",
//     SELECT_BUYING_PERIOD: "-Select Buying Period-",
//     FORM_CONDITION: ` By completing and submitting this form you consent to our policy
//     regarding information collection as outlined in our`,
//     TICK_THE_BOX: "Tick the box to let us know you’ve read and understood our ",

//     CONTACT_US_INFO: {
//       FIRST_POINT: `(By consent for direct marketing information, you agree for processing and use of your identification data (full name, business email address and phone number (collectively, "Personal Data")`,
//       SECOND_POINT: `For direct marketing purposes`,
//       THIRD_POINT: `To Suzuki, meaning Magyar Suzuki Corporation and dealer network companies. You can find the list of Suzuki Dealers by accessing here the link , (add link to dealer section)`,
//       FOURTH_POINT: ` Marketing information may include information about Suzuki test drives, events special offers, services during a dealer visit, new developments, events information, maintenance and recall campaigns..`,
//       FIFTH_POINT: `Withdrawing your consent or any other or any other rights can be exercise by you, at you moment, by accessing https://website.suzukibusinesscloud-qa.com/update-consent Or email at dpo@suzuki-digital.com, or via post office at……`,
//       SIXTH_POINT: `Your data will be kept for 6 years or up to withdraw your consent.)`,
//     },
//     ALL_FORM_INFO: {
//       FIRST_POINT: `(By consent for direct marketing information, you agree for processing and use of your identification data (full name, business email address and phone number (collectively, "Personal Data")`,
//       SECOND_POINT: `For direct marketing purposes`,
//       THIRD_POINT: `To Suzuki, meaning Magyar Suzuki Corporation and selected preferred dealership.`,
//       FOURTH_POINT: ` Marketing information may include information about Suzuki test drives, events special offers, services during a dealer visit, new developments, events information, maintenance and recall campaigns..`,
//       FIFTH_POINT: `Withdrawing your consent or any other or any other rights can be exercise by you, at you moment, by accessing https://website.suzukibusinesscloud-qa.com/update-consent Or email at dpo@suzuki-digital.com, or via post office at……`,
//       SIXTH_POINT: `Your data will be kept for 6 years or up to withdraw your consent.)`,
//     },

//     CITY: "City",
//     YOUR_CITY: "Your City",
//     EMAIL_ADDRESS: "Email Address",
//     YOUR_EMAIL_ADDRESS: "Your Email Address",
//     PREFFERED_OPTIONS: "Preferred Option",
//     FINANCE_OPTION: "Finance Option",
//     FINANCING_OPTION: "Financing Option*",
//     SELECT_FINANCING_OPTION: "Select Financing Option",
//     PLANNING_TO_PURCHASE_IN: "Planning to Purchase In",
//     SELECT_ALL: "Select All",
//     MANDOTRYDISCLAIMER:"The consents marked in * are mandatory",
//     ALL: "ALL",
//     OPTIONAL_CONSENT: "OPTIONAL CONSENT",
//     MARKETING_CHANNELS: "MARKETING CHANNELS",
//     AGREE_CONSENT: "I would like to recieve such marketing information via",
//     MARKETING_CONSENTS_WARNING: "Please select preferred communication channel",
//     BOTH: "Both",
//     Marketing_By_Suzuki: "Marketing by Suzuki",
//     Marketing_By_Suzuki_Dealers: "Marketing by Suzuki Dealers",
//     Info_About_Button:
//       "You can access our Privacy Notice by clicking on the following button",
//     View_Privacy_Notice: "View Privacy Notice",
//     Consent_Information: "Consent Information",
//     Right_To_Withdraw_Consent: "Right To Withdraw Consent",
//     WithDraw_Consent_Info_1:
//       "I can withdraw this consent at any time and I understand that such a withdrawal would not affect the lawfulness of such direct marketing activities before the withdrawal. I was informed that I can easily withdraw my consent by sending an email to",
//     WithDraw_Consent_Info_2: "or to privacy@suzuki.hu",
//     WithDraw_Consent_Info_3: "or unsubscribing on the unsubscribe",
//     WithDraw_Consent_Info_4: "displayed in such communication.",
//     //  dataprotection@suzuki.ro or to privacy@suzuki.hu or unsubscribing on the unsubscribe link displayed in such communication.",
//     Mandatory_Consents: "Mandatory Consents",
//     FORM_SUBMITTED_SUCCESSFULLY_EXECUTIVE_WILL_REACH_YOU_SOON:
//       "Form successfully submitted. Our exec will reach you out shortly",
//       MANDOTRYDISCLAIMER  :"The consents marked in * are mandatory",
//   },
//   error: {
//     DESCRIPTION: "Sorry, the page you’re looking for doesn’t exist",
//     BUTTON_TEXT: "Go Back",
//     ERROR_BOUNDRY_MESSAGE: "Oops, there is an error!",
//     TRY_AGAIN: "Try again?",
//   },
//   contactUs: {
//     CONTACT_US: "Contactează-ne",
//     PHONE: " Phone",
//     CARE_HOTLINE_NUMBER: " Toll Free Customer Care Hotline:",
//     ADDRESS: "Address",
//     ADDRESS_LINE_1:
//       "126 Progress Avenue, Carmelray Industrial Park 1, Carmeltown,Canlubang",
//     ADDRESS_LINE_2: " Calamba City, 4028, Laguna",
//     COUNTRY: "Philippines",
//     EMAIL_ADDRESS: "Email Address",
//     EMAIL: "helpdesk@suzuki.com.ph",
//     NAVIGATE_TO_ADDRESS: "Navigate to address",
//     SEND_EMAIL: "Send Email",
//   },
//   faqs: {
//     FREQUENTLY_ASKED_QUESTION: "Frequently asked questions",
//     SHOW_LESS: "Show Less",
//     FURTHER_QUESTIONS: "Further Questions",
//   },
//   gallery: {
//     GALLERY: "Gallery",
//   },
//   getAQuote: {
//     GET_A_QUOTE_TITLE: "Get a Quote",
//     GET_A_QUOTE: "GET A QUOTE",
//     QUOTE_DESC: `  We've made it easier for you to own your dream Suzuki vehicle.
//     Just fill out the form and submit. Our competent Sales Executives will
//     be in touch soon. Make sure to check your email and keep your lines
//     open.`,
//   },
//   videos: {
//     VIDEO: "VIDEO",
//   },
//   brandBanner: {
//     RETAIL_PRICE: "Retail Price",
//   },
//   brandCard: {
//     PREV: " Prev",
//     NEXT: " Next",
//   },
//   bookMarkMenu: {
//     CAR_DETAILS: "Car Details",
//   },
//   calculatePayment: {
//     MONTH: "month",
//     CALCULATED_ON_AMOUNT: "Calculated on amount",
//     CONNECT_SUZUKI_DEALER: ` Computations are estimate only. Please contact any Suzuki
//     Dealer for final quotation.`,
//   },
//   compare: {
//     ADD_VARIANT: "Add variant",
//     INCLUDED: "Included",
//   },
//   findSuzuki: {
//     TERMS_AND_CONDITIONS: "Terms and Conditions",
//   },
//   findDealer: {
//     LOADING: "Loading",
//     NO_DEALERS_FOUND:
//       "No dealers found near you, Please. try for some other location.",
//     MAP: "Map",
//     LIST: "list",
//   },
//   locationCard: {
//     DISTANCE: "Distance",
//     OPEN: "Open",
//     CLOSED: "Closed",
//     BOOK_A_TEST_DRIVE: "Book a test drive",
//     BOOK_A_SERVICE: "Book a service",
//     BOOK_A_CALLBACK: "Book a Callback",
//     SALES:"Sales",
//     SERVICE:"Service",
//     SALES_AND_SERVICE:"Sales And Service"
//   },
//   fleet: {
//     MODEL: "Model",
//     LIST_PRICE: "List price",
//     DISCOUNTED_PRICE: "Discounted price",
//   },
//   floatingMenu: {
//     DEALERS: "Dealers",
//     TEST_DRIVE: "Test Drive",
//     BROCHURE: "Brochure",
//     INQUIRE: "Inquire",
//   },
//   layout: {
//     YOUR_PROFILE: "Your Profile",
//     SETTINGS: "Settings",
//     SIGN_OUT: "Sign out",
//     OPEN_MAIN_MENU: "Open main menu",
//     TYPE_HERE: "Type Here..",
//     VIEW_NOTIFICATION: "View notifications",
//     Floatbar_Title: {
//       Dealers: "Dealers",
//       Get_a_Quote: "Get a Quote",
//       Test_Drive: "Test Drive",
//       Brochure: "Brochure",
//       Inquire: "Inquire",
//       Book_a_Service: "Book a Service",
//       Contact_us: "Contact us",
//     },
//   },
//   preferenceProgramme: {
//     FROM_COUNTRY: "From HUF",
//     PER_MONTH: "per month",
//   },
//   searchInputLocation: {
//     LOCATION: "Location",
//   },
//   // planningToPurchaseOptions: {
//   //   WITH_IN_30_DAYS: "within in 30 days",
//   //   ONE_THREE_MONTHS: "1-3 months",
//   //   THREE_SIX_MONTHS: "3 - 6 months",
//   //   SIX_TWELVE_MONTHS: "6 – 12 months",
//   //   ONE_TWO_YEARS: "1 – 2 years",
//   //   UNDECIDED: "undecided",
//   // },
//   planningToPurchaseOptionsName:
//     process.env.NEXT_PUBLIC_PLANNING_TO_PURCHASE_IN_NAME,
//   planningToPurchaseOptionsCode:
//     process.env.NEXT_PUBLIC_PLANNING_TO_PURCHASE_IN_CODE,
//   communicationOptions: {
//     BOTH: "All",
//   },
//   communicationOptionsName:
//     process.env.NEXT_PUBLIC_PREFERRED_COMMUNICATION_MODE_NAME,
//   communicationOptionsValue:
//     process.env.NEXT_PUBLIC_PREFERRED_COMMUNICATION_MODE_VALUE,
//   communicationOptionsFlag:
//     process.env.NEXT_PUBLIC_PREFERRED_COMMUNICATION_MODE_FLAG,
//   purchaseOptions: {
//     CREDIT: "CREDIT",
//     COUNTED: "COUNTED",
//     LEASE: "LEASE",
//   },
//   allcomponents: {
//     AVAILABLE_FOR_THE_MODELS_BELOW: "AVAILABLE FOR THE MODELS BELOW",
//     TERMS_AND_CONDITIONS: "Terms and Conditions",
//     SHOW_TERMS_AND_CONDITIONS_MULTITAB:
//       process.env.NEXT_PUBLIC_SHOW_TERMS_AND_CONDITIONS_MULTITAB,
//   },
//   EMICALCULATOR: {
//     TITLE: "EMI CALCULATOR",
//     EMI_CALCULATE_TITLE: "Calculate Your Vehicle Loan EMI",
//     LABEL_MODEL: "Model",
//     SELECT_MODEL: "Select Model",
//     LABEL_VARIANT: "Variant",
//     SELECT_VARIANT: "Select Variant",
//     LABEL_CITY: "City",
//     SELECT_CITY: "Search City",
//     SELECT_METHOD: "Select Method",
//     LABEL_DOWNPAYMENT: "Downpayment",
//     LABEL_LOAN: "Loan Amount",
//     MODEL_PRICE: "Model Price",
//     LABEL_MONTHS: "Tenure (Months)",
//     MONTHS: "Months",
//     LABEL_INREST: "Intrest Rate (%)",
//     CALCULATE_BTN: "CALCULATE",
//     EMI_AMOUNT: "EMI Amount : ",
//     VEHICLE_PRICE_DETAILS: "Vehicle Price Details :",
//     PRICE_OF_VEHICLE: "Price of vehicle",
//     STANDARD_ACCESSORIES_PACKAGE: "Standard Accessories Package",
//     TOTAL_PRICE: "Total Price",
//     DOWN_PAYMENT_AMOUNT: "Down payment Amount",
//     INSURANCE_AMOUNT: "Insurance Amount",
//     OPENING_TAX: "Opening Tax",
//     TOTAL_INTITAL_PAYMENT: "Total Initial Payment",
//     LOAN_AMOUNT: "Loan Amount",
//     INTREST_RATE: "Interest rate",
//     DISCLAIMER: "Disclaimer:",
//     DISCLAIMER_DATA:
//       "Prices are indicative and its applicability is subject to the date of invoicing of the vehicle. For specific details, you may visit your nearest:",
//     DEALERSHIP: "dealership.",
//     ADD_LEVEL: process.env.NEXT_PUBLIC_ADDRESS_LEVEL_EMICALCULATOR,
//     MAX_INTEREST_RATE: process.env.NEXT_PUBLIC_MAX_INTEREST_RATE_EMICALCULATOR,
//     PER_MONTH: "/Month",
//     REQUEST_A_CALLBACK: "REQUEST A CALL BACK",
//     FIND_DEALER: "FIND A DEALER",
//   },
//   carConfigurator: {
//     EXTERIOR: " EXTERIOR",
//     INTERIOR: "INTERIOR",
//     GRAND_TOTAL: "Grand Total",
//     EX_SHOWROOM_PRICE: " Ex showroom price",
//     AUTOMATIC: " Automatic",
//     MANUAL: "Manual",
//     VARIANT: "Variant",
//     PAINT: "PAINT",
//     ADDITIONAL_FITMENTS: "ADDITIONAL FITMENTS",
//     WHEELS: "Wheels",
//     OTHERS: "OTHERS",
//     SUMMARY: "SUMMARY",
//     TOTAL: "Total",
//     NO_AUTOMATIC_VARIANT_IS_FOUND: "There is no  automatic variant",
//     NO_MANUAL_VARIANT_IS_FOUND: "There is no manual variant",
//     SUBMIT: "Submit",
//     CONFIGURATOR: "CONFIGURATOR",
//     CONFIGURATOR_DESCRIPTION:
//       "Create your individual Suzuki - exactly for your lifestyle!",
//     SUBMIT: "SUBMIT",
//     DISCLAIMER: "&#9432; Creative visualization & may vary from actual product",
//   },
//   catalog_page: {
//     TITLE: "ACCESSORIES CATALOG",
//     DISCOVER_SUZUKI_ACCESSORIES: "DISCOVER SUZUKI ACCESSORIES",
//     CTA_TITLE: "DOWNLOAD CATALOG",
//   },
// };
// const isObject = (input) => typeof input === "object" && input !== null;

// const flatten = (source) =>
//   isObject(source)
//     ? Object.fromEntries(
//         Object.entries(source).flatMap(([key, value]) =>
//           ((flattenValue) =>
//             isObject(flattenValue)
//               ? Object.entries(flattenValue).map(([valueKey, valueValue]) => [
//                   `${key}.${valueKey}`,
//                   valueValue,
//                 ])
//               : [[key, value]])(flatten(value)),
//         ),
//       )
//     : source;

// const csvToJson = (finalObje) => {
//   for (let _key in finalObje) {
//     // code block to be executed

//     const keys = _key?.split(".");
//     let ptr = Constants;
//     while (keys.length > 1) ptr = ptr[keys.shift()];
//     ptr[keys.shift()] = finalObje[_key];
//   }
// };
