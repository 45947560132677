import { formNames } from "../components/forms/Validation";
import {
  TOGGLE_BROCHURE_FORM,
  TOGGLE_BROCHURE_FORM_2W,
  TOGGLE_CONTACT_FORM,
  TOGGLE_ENQUIRY_FORM,
  TOGGLE_FINANCE_FORM,
  TOGGLE_GET_QUOTE_FORM,
  TOGGLE_GET_QUOTE_FORM_2W,
  TOGGLE_SERVICE_FORM,
  TOGGLE_SERVICE_FORM_2W,
  TOGGLE_TEST_DRIVE_FORM,
  OFFER_DETAIL_FORM,
  TOGGLE_PERSONAL_IDENTIFIABLE_INFORMATION_FORM,
  TOGGLE_TEST_DRIVE_FORM_2W,
  TOGGLE_ENQUIRY_FORM_2W,
} from "./ActionTypes";

export const toggleForm = (formName) => {
  switch (formName) {
    case formNames.CALC_PAYMENT:
      return {
        type: TOGGLE_FINANCE_FORM,
      };
    case formNames.BROCHURE:
      return {
        type: TOGGLE_BROCHURE_FORM,
      };
    case formNames.BROCHURE_2W:
      return {
        type: TOGGLE_BROCHURE_FORM_2W,
      };
    case formNames.ENQUIRY:
      return {
        type: TOGGLE_ENQUIRY_FORM,
      };
    case formNames.ENQUIRY_2W:
      return {
        type: TOGGLE_ENQUIRY_FORM_2W,
      };
    case formNames.TEST_DRIVE:
      return {
        type: TOGGLE_TEST_DRIVE_FORM,
      };
    case formNames.TEST_DRIVE_2W:
      return {
        type: TOGGLE_TEST_DRIVE_FORM_2W,
      };
    case formNames.SERVICE:
      return {
        type: TOGGLE_SERVICE_FORM,
      };
    case formNames.SERVICE_2W:
      return {
        type: TOGGLE_SERVICE_FORM_2W,
      };
    case formNames.CONTACT_US:
      return {
        type: TOGGLE_CONTACT_FORM,
      };
    case formNames.GET_QUOTE:
      return {
        type: TOGGLE_GET_QUOTE_FORM,
      };
    case formNames.GET_QUOTE:
      return {
        type: TOGGLE_GET_QUOTE_FORM,
      };
    case formNames.GET_QUOTE_2W:
      return {
        type: TOGGLE_GET_QUOTE_FORM_2W,
      };
    case formNames.ENQUIRY_2W:
      return {
        type: TOGGLE_ENQUIRY_FORM_2W,
      };
    default:
      return {
        type: "TOGGLE_DYNAMIC_FORM",
        payload: formName, // Pass the dynamic form name
          };
  }
};
