import React, { forwardRef } from "react";
// import ToolTip from "../Tool-tip";
import { Tooltip } from "react-tooltip";
import Styles from "./Forms.module.css";
import Style from "./Forms.module.css";
import styles from "../Tool-tip/tool-tip.module.css";
import Image from "next/image";
import {configuration} from "../../../helpers/config"

// InputField component accepts various props for customization
// eslint-disable-next-line react/display-name
const InputField = forwardRef(
  (
    {
      error,
      label,
      required,
      name,
      placeholder,
      mandatory,
      showTooltip,
      imageVar,
      tooltipContent,
      iconSource,
      descript,
      minLength,
      maxLength,
      srcIcon,
      showIcons,
      ...props
    },
    ref,
  ) => {

  const handleInput = (e) => {
    // Reset the height to auto to shrink the textarea before resizing
    e.target.style.height = '50px';

    // Set the height to scrollHeight, but limit it to a maximum of 100px
    e.target.style.height = `${Math.min(e.target.scrollHeight, 100)}px`;
  };

console.log("minLength---->",minLength)
// Function to extract text from the label
const extractPlaceholder = (html) => {
  const decodedHtml = html
    ?.replace(/&nbsp;/g, ' ') 
    ?.replace(/&amp;/g, '&') 
    ?.replace(/&lt;/g, '<')
    ?.replace(/&gt;/g, '>');  

  const text = decodedHtml?.replace(/<\/?[^>]+(>|$)/g, ' '); 
  return text?.replace(/\s+/g, ' ')?.trim() || ''; 
};

const placeholderText = extractPlaceholder(label);

    return (
      <div className="">
        {/* Container for the input field and label */}
        <div className="label-icon flex justify-between relative h-[29px] ">
          {/* Label for the input field */}
          <div className="flex starstyle">
          {label && (
          <label
              className={Styles.label}
              htmlFor={name}
              dangerouslySetInnerHTML={{ __html: label }}
            />    
          )}
          {required && <span>*</span>}
          </div>
          {/* ToolTip - Commented out, but can be used */}
          {/* <ToolTip
            infoIcon = {`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            toolImg = {`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoTip.svg`}
            desc = {props.desc}
            toolposition = {props.toolposition}
            arrowPosition = {props.arrowPosition}
          /> */}

          {/* Display tooltip if showTooltip is true */}
          {showTooltip && (
            <>
              <div className="tool-tip-img">
                {/* Tooltip icon */}
                <Image
                  data-tooltip-id={name}
                  src={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
                  height="15"
                  width="15"
                />
              </div>
              <Tooltip
                id={name}
                src={imageVar}
                variant="light"
                className="shadow-lg z-10"
              >
                <div className="tool-tip-wrap flex items-center justify-between">
                  <div className="w-[20px] flex items-center mr-1 ">
                    {/* Icon inside the tooltip */}
                    <Image src={iconSource} width={15} height={15} />
                  </div>
                  {/* Tooltip description */}
                  <p className={styles.toolTip_desc}>{descript}</p>
                </div>
              </Tooltip>
            </>
          )}
        </div>

        {/* Input field */} 
      <div className="relative">
  {props.placeholder ? (
    // If a placeholder is provided, show the input directly
    <input
      className={error ? Styles.inputError : Styles.input}
      minLength={minLength}
      maxLength={maxLength}
      name={name}
      placeholder={placeholderText}
      required={mandatory}
      {...props}
      ref={ref}
    />
  ) : (
    // Else show the custom placeholder and input together
    <>
      <div
        className={`absolute pointer-events-none customPlaceholder`}
      />
      {(configuration?.COUNTRY_CODE === "RO" || configuration?.COUNTRY_CODE === "HUN") && props?.type === "remarks" ?
        <textarea
          className={error ? Styles.remarksInputError : Styles.remarksInput}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholderText}
          name={name}
          onInput={handleInput}  // Handle input to adjust height
          required={mandatory}
          {...props}
          ref={ref}
          onFocus={(e) => (e.target.previousSibling.style.display = 'none')}  // Hide the custom placeholder on focus
          onBlur={(e) => {
            if (!e.target.value) e.target.previousSibling.style.display = 'block';
          }}  // Show custom placeholder if input is empty
      />
       
      : 
      <input
        className={error ? Styles.inputError : Styles.input}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholderText}
        name={name}
        required={mandatory}
        {...props}
        ref={ref}
        onFocus={(e) => (e.target.previousSibling.style.display = 'none')}  // Hide the custom placeholder on focus
        onBlur={(e) => {
          if (!e.target.value) e.target.previousSibling.style.display = 'block';
        }}  // Show custom placeholder if input is empty
      /> 
      }
    </>
  )}
 

           
            {
              showIcons &&
                <div className="fieldIcon">
                  <Image
                    src={srcIcon}
                    height="18"
                    width="18"
                    priority={true}
                  />
                </div>
            }
        </div>

        {/* Display error message if error exists */}
        <p className={Style.error}>{error}</p>
      </div>
    );
  },
);

export default InputField;
