import { Modal } from "react-responsive-modal";
import DynamicFormComponent from "./DynamicFormComponent";

//component to render dynamic forms in a modal.
export default function DynamicForm({ data, open, onClose ,is_share_brochure}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{
        modal: "form-modal__container",
      }}
    >
      {/*component that renders the form data*/}
      <DynamicFormComponent data={data} onClose={onClose} is_share_brochure={is_share_brochure}/>
    </Modal>
  );
}
