import React from "react";
import { Constants } from "../helpers/constants";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>{Constants.error.ERROR_BOUNDRY_MESSAGE}</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            {Constants.error.TRY_AGAIN}
          </button>
        </div>
      );
    }
    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
