import React, { useState, useEffect, useRef } from "react";
import { configuration } from "../../../helpers/config";
import { useSelector} from "react-redux";
import { Constants } from "../../../helpers/constants";

export default function CustomDropdown({
  value,
  onChange,
  handleSelectChange,
  options,
  error,
  required,
  id,
  placeholder,
  label,
  setdealerconsentadress,
}) {
  const {is_dealer_selected,selected_dealer} =useSelector((state)=>state.app);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(is_dealer_selected && selected_dealer && selected_dealer?.name?selected_dealer?.name: Constants.forms.PREFFERED_DEALERSHIP ? Constants.forms.PREFFERED_DEALERSHIP : "-Preferred Dealership-");
  const [border, setBorder] = useState(false);
  const [selectedOption, setSelectedOption] = useState(is_dealer_selected && selected_dealer && selected_dealer?.index?selected_dealer?.index:0);
  const focusRef = useRef();
  const sanitizedLabel = label.replace(/<[^>]*>/g, ''); // Removes all HTML tags


  useEffect(() => {
    if(open) {
      focusRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      })
    }
    
},[open])

  const toggleDropdown = () => {
    setOpen((op) => !op);
    setBorder(true);
  };

  const handleSelectBoxBorder = () => {
    setOpen(false);
    setBorder(false);
  };

  const handlePlaceHolder = () => {
    onChange("");
    setName(placeholder);
    setSelectedOption(0)
    setOpen(false)
  }

  const handleOptions = (e, option) => {
    handleSelectChange(e);
    onChange(JSON.stringify(option));
    setOpen(false);
    setName(
      option.name || option.dealerName +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" &&
      option?.addressLevel1?.name
        ? ` - ${option?.addressLevel1?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" &&
      option?.addressLevel2?.name
        ? ` - ${option?.addressLevel2?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" &&
      option?.addressLevel3?.name
        ? ` - ${option?.addressLevel3?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" &&
      option?.addressLevel4?.name
        ? ` - ${option?.addressLevel4?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" &&
      option?.addressLevel5?.name
        ? ` - ${option?.addressLevel5?.name}`
          : "") +

          (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" &&
            option?.value?.addressLevel1?.name
              ? ` - ${option?.value?.addressLevel1?.name}`
              : "") +
          (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" &&
            option?.value?.addressLevel2?.name
              ? ` - ${option?.value?.addressLevel2?.name}`
              : "") +
  
          (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" &&
            option?.value?.addressLevel3?.name
            ? ` - ${option?.value?.addressLevel3?.name}`
             : "") +
          (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" &&
            option?.value?.addressLevel4?.name
            ? ` - ${option?.value?.addressLevel4?.name}`
            : "") +
          (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" &&
            option?.value?.addressLevel5?.name
            ? ` - ${option?.value?.addressLevel5?.name}`
            : "") 
          
    );
  }
  return (
    <div className="custom-dropdown" onMouseLeave={handleSelectBoxBorder}>
     <div className="label-text"> 
      <span dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
         {required && <span>*</span>}
      </div>
      <div style={error ? { border: "2px solid #FF9494" } : {}}  className={`selected-option ${border ? "select-border" : ""}`} onClick={toggleDropdown}>
        {name.toUpperCase()}
      </div>
      {error && <p className="error">{error}</p>}
      {open && (
        <div className="dropdown-options">
          <div className="option-default" onClick={handlePlaceHolder}>
            {placeholder}
          </div>
          {options?.map((option, index) => (
              <div
              key={option.name || option.dealerName}
                id={id}
                className="option"
                onClick={(e) => {
                handleOptions(e, option)
                setSelectedOption(index + 1)
                setdealerconsentadress(option);
                }}
                onMouseLeave={(e) => handleSelectChange(e)}
                style={selectedOption === index + 1 ? {backgroundColor: 'lightgray', color: '#000'} : {}}
                ref={selectedOption === index + 1 ? focusRef : undefined}
              >
                {option.name|| option.dealerName}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_1 == "true" &&
                option?.addressLevel1?.name
                 ? ` - ${option.addressLevel1?.name }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_2 == "true" &&
                option?.addressLevel2?.name
                  ? ` - ${option.addressLevel2?.name }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_3 == "true" &&
                option?.addressLevel3?.name
              ? ` - ${option.addressLevel3?.name  }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_4 == "true" &&
                option?.addressLevel4?.name
                ? ` - ${option.addressLevel4?.name }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_5 == "true" &&
                option?.addressLevel5?.name
                    ? ` - ${option.addressLevel5?.name }`
                  : ""}


                {configuration.SHOW_DEALER_ADDRESS.LEVEL_1 == "true" &&
                option?.value?.addressLevel1?.name
                 ? ` - ${ option?.value?.addressLevel1?.name }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_2 == "true" &&
                option?.value?.addressLevel2?.name
                  ? ` - ${option?.value?.addressLevel2?.name }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_3 == "true" &&
                option?.value?.addressLevel3?.name
              ? ` - ${ option?.value?.addressLevel3?.name }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_4 == "true" &&
                option?.value?.addressLevel4?.name
                ? ` - ${ option?.value?.addressLevel4?.name }`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_5 == "true" &&
                option?.value?.addressLevel5?.name
                    ? ` - ${option?.value?.addressLevel5?.name }`
                  : ""}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}