import React from "react";

// loader component
const Loader = ({ centerMode }) => {
  return (
    <div className={`${centerMode ? "fixed" : "absolute"} top-1/2 translate-x-[-50%] translate-y-[-50%] left-1/2 z-10`}>
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
