export  function UTM_ID_FROM_SOURCE(utmSource) {
    try {
      let utm_source_id=""
      switch (utmSource) {
          case "google":
              utm_source_id=1664257120280;
            break;
          case "facebook":
              utm_source_id=1664257076611;
            break;
          case "fb_feed":
              utm_source_id=1708952935759;
            break;
          case "fb_organic":
              utm_source_id=1708952891479;
            break;
          case "gmb":
              utm_source_id=1664257132450;
            break;
          case "prog_video":
              utm_source_id=1708952603128;
            break;
          case "online_publications":
              utm_source_id=1705389537952;
            break;
          case "organic/direct":
              utm_source_id=1664257061811;
            break;
          case "youtube":
              utm_source_id=1705388941842;
            break;
          case "yt_bumper":
              utm_source_id=1708952539923;
            break;
          case "facebook - lead generation":
              utm_source_id=1664257106391;
            break;
          case "free_tv":
              utm_source_id=1708952624938;
            break;
          case "article":
              utm_source_id=1708952686518;
            break;
          case "tiktok":
              utm_source_id=1705389478201;
            break;
          case "proggreen_display":
              utm_source_id=1708952903247;
            break;
          case "fb_like":
              utm_source_id=1708952920331;
            break;
          case "prog_display":
              utm_source_id=1708952552049;
            break;
          case "tiktok_video":
              utm_source_id=1708952653518;
            break;
          case "ig_organic":
              utm_source_id=1708952877142;
            break;
          case "prog_gdn":
              utm_source_id=1705388911110;
            break;
          case "proggreen_display":
              utm_source_id=1708952717159;
            break;
          case "proggreen_video":
              utm_source_id=1708952945905;
            break;
          case "google_search":
              utm_source_id=1705389206786;
            break;
          case "yt_truview":
              utm_source_id=1708952524778;
            break;
          case "google":
              utm_source_id=1664257120280;
            break;
          case "yfreetv_tvsync":
              utm_source_id=1705389248943;
            break;
          case "programmatic":
              utm_source_id=1705389427405;
            break;
          case "yt_organic":
              utm_source_id=1708952886518;
            break;
          case "yt_mediapartner":
              utm_source_id=1708952899011;
            break;
          case "yt_mirrors":
              utm_source_id=1705389656854;
            break;
          case "tiktok_organic":
              utm_source_id=1708952871215;
            break;
          case "fb_lead":
              utm_source_id=1708952928331;
            break;
          case "fb_video":
              utm_source_id=1708952910505;
            break;
          case "organic":
              utm_source_id=1717588131778;
            break;
          case "direct":
              utm_source_id=1717588141715;
            break;
          default:
        }
        return utm_source_id;
    } catch (err) {
      return err;
    }
  }
  