import Layout from "../components/layout/layout";
import "../styles/globals.css";
import "../styles/unreset.css";
import ErrorBoundary from "../components/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import Loader from "../components/Loader/Loader";
import { Router, useRouter } from "next/router";
import { Provider } from "react-redux";
import store from "../redux/store";
import AcceptCookies from "../components/acceptCookies";
import { configuration } from "../helpers/config";
// import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import useGoogleTranslateFix from "../hooks/useGoogleTranslateFix"; // Import the hook
import Script from "next/script";

function MyApp({ Component, pageProps }) {
  // console.log("pageProps",pageProps);
  const [loading, setLoading] = React.useState(false);
  // const [growthbook, setGrowthbook] = React.useState(null);
  const router = useRouter();
  useGoogleTranslateFix(); // Apply Google Translate fix


  useEffect(() => {
    // Get the current domain URL without the path
    const currentBaseUrl = window.location.host;
  }, [router.pathname]);

  useEffect(() => {
    const { asPath } = router;

    if (asPath !== asPath.toLowerCase()) {
      router.push(asPath.toLowerCase());
    }
  }, [router]);

  React.useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeComplete", handleRouteChange);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  // useEffect(() => {
  //   // Initialize GrowthBook
  //   const gb = new GrowthBook({
  //     apiHost: configuration.GROWTHBOOK_API_HOST,
  //     clientKey: configuration.GROWTHBOOK_CLIENT_KEY,
  //     enableDevMode: true,
  //     attributes:{COUNTRY : configuration.COUNTRY_CODE},
  //     fetch,
  //   });

  //   // Load features from GrowthBook
  //   gb.loadFeatures()
  //     .then(() => {
  //       console.log("Features loaded:", gb.getFeatures());
  //       setGrowthbook(gb); // Set GrowthBook instance
  //     })
  //     .catch((error) => {
  //       console.error("Error loading GrowthBook features:", error);
  //     });
  // }, []);

  // if (!growthbook) {
  //   return (
  //     <ErrorBoundary>
  //       <ToastContainer />
  //       <Loader centerMode />
  //     </ErrorBoundary>
  //   );
  // }
  // else{

       // Initialize GrowthBook
    //    const growthbook = new GrowthBook({
    //     apiHost: configuration.GROWTHBOOK_API_HOST,
    //     clientKey: configuration.GROWTHBOOK_CLIENT_KEY,
    //     enableDevMode: true,
    //     attributes:{COUNTRY : configuration.COUNTRY_CODE},
    //     fetch,
    //     trackingCallback: (experiment, result) => {
    //       console.log("Experiment Viewed:", { experiment, result });
    //     },
    //   });
  
    // useEffect(() => {
    //   growthbook.init({ streaming: true });
    // }, []);

  return (
    <ErrorBoundary>
      <ToastContainer />
      {(loading) && <Loader centerMode />}
      {/* <GrowthBookProvider growthbook={growthbook}> */}
      <Provider store={store}>
        {configuration.IS_ROUTE_GUARD_IMPLENTED == "true" ? (
          <Layout menu={pageProps?.Menudata } footer={pageProps?.Footerdata}>
            <Component {...pageProps} />
          </Layout>
        ) : (
          <Layout menu={pageProps?.Menudata } footer={pageProps?.Footerdata}>
            <Component {...pageProps} />
          </Layout>
        )}
        {/* {(!configuration.COUNTRY_CODE == "IN" || "PH") &&
        configuration.CMP_FLAG == "false" ? (
          <AcceptCookies />
        ) : null} */}

        {(!configuration.COOKIEBOT.includes(configuration.COUNTRY_CODE) &&
          !configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE)) ||
        (configuration.COOKIEBOT.includes(configuration.COUNTRY_CODE) &&
          configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE)) ? (
          <AcceptCookies />
        ) : null}
        {/* SPH GOOGLE ADS MANAGER STARTS */}
        {   configuration?.COUNTRY_CODE=="PH"?
        <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=AW-16800370186`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16800370186');
        `}
      </Script>
      </>
      :""}
      </Provider>
      {/* </GrowthBookProvider> */}
    </ErrorBoundary>
  );
}
// }

export default MyApp;
