import Image from "next/image";
import { useEffect, useState } from "react";
import { configuration } from "../../helpers/config";
import { useRouter } from "next/router";

const MegaMenuMobile = ({ data, models, close }) => {
  const [modelsData, setModelsData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setModelsData(data);
  }, [data, selectedIndex]);

  function getImageKeys(models, modelName, variantName, colorName) {
    const imageKeys = [];
    models?.forEach((model) => {
      // Check if the model_name matches

      if (model?.model_name === modelName) {
        // Iterate through the variants to find the matching variant_name

        model?.variants?.forEach((variant) => {
          if (variant?.variant_name === variantName) {
            // Iterate through the colors to find the matching color_name

            variant?.colors?.forEach((color) => {
              if (color?.color_name === colorName) {
                // If all three values match, push the image key to the result
                imageKeys.push(color?.image);
              }
            });
          }
        });
      }
    });
    return imageKeys;
  }

  const router = useRouter();

  const handleRedirect = (url) => {
    router.push(url);
    close();
  };

  return (
    <div className="py-1 rounded-sm grid grid-cols-2 md:grid-cols-3">
      {modelsData?.map((nestedSubmenu, x) => (
        <>
          <div
            className="grid justify-items-center hover:bg-[#E5E5E5] cursor-pointer px-3"
            onClick={() => handleRedirect(nestedSubmenu?.url)}
          >
            <Image
              src={`${configuration.IMG_URL}${getImageKeys(
                models,
                nestedSubmenu.model,
                nestedSubmenu.variant,
                nestedSubmenu.color
              )}`}
              width={164}
              height={106}
              alt=""
            />
            <p className="text-center font-suzukiHeadline text-base uppercase">
              {nestedSubmenu?.text}
            </p>
            <div className=" bg-primary-blue rounded-lg text-[white] py-2 px-3 w-fit font-suzukiRegular text-sm">
              Explore Model
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default MegaMenuMobile;
